<template>
  <CSidebar fixed :minimize="minimize" :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
    <CSidebarBrand class="d-md-down-none" to="/">
      <!-- <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      /> -->
      <CImg src="img/logo-dashboard.png" fluid height="100px" style="padding: 10px;" />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="filteredNav" />
    <CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('set', ['sidebarMinimize', !minimize])" />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav,
  computed: {
    filteredNav() {
      return nav.map(group => ({
        ...group,
        _children: group._children.filter(item => {
          // Check if the item has a permission defined and if the user has this permission
          return !item.permission || this.can(item.permission);
        })
      })).filter(group => group._children.length > 0); // Filter out empty groups
    },
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  methods: {
    can(permission) {
      return this.$store.getters.can(permission);
    }
  }
};
</script>
