<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <!-- <CIcon name="logo" height="48" alt="Logo"/> -->
      <CImg
        src="img/header-logo.png"
        fluid
        height="40px"
        width="100px"
      />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto"></CHeaderNav>
    <CHeaderNav class="mr-4">
      <!-- <Notifications/> -->
      <TheHeaderDropdownAccnt :user="user"/>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt';
import Notifications from './Notifications';

export default {
  name: 'TheHeader',
  created() {
    this.user = JSON.parse(localStorage.getItem("admin-data"));
  },
  data() {
    return {
      user: null,
    };
  },
  components: {
    TheHeaderDropdownAccnt,
    Notifications
  }
}
</script>
