export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'الرئيسية',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'المحافظات',
        to: '/states',
        icon: 'cil-location-pin',
        permission: 'dashboard.states.list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'المدن',
        to: '/cities',
        icon: 'cil-location-pin',
        permission: 'dashboard.cities.list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'الاقسام',
        to: '/categories',
        icon: 'cil-list',
        permission: 'dashboard.categories.list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'الاقسام الفرعية',
        to: '/sub-categories',
        icon: 'cil-list',
        permission: 'dashboard.subcategories.list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'الخدمات',
        to: '/services',
        icon: 'cil-list',
        permission: 'dashboard.services.list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'المستخدمون',
        to: '/users',
        icon: 'cil-people',
        permission: 'dashboard.users.list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'أعضاء الا دارة',
        to: '/admins',
        icon: 'cil-star',
        permission: 'dashboard.admins.list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'الصلاحيات',
        to: '/roles',
        icon: 'cil-star',
        permission: 'dashboard.roles.list'
      }
    ]
  }
]