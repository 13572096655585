<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            src="img/avatars/avatar.png"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>البيانات الشخصية</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-user" />
      {{ user ? user.name : '--' }}
    </CDropdownItem>
    <CDropdownItem @click.prevent="logout">
      <CIcon name="cil-lock-locked" /> خروج
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  props: ["user"],
  data () {
    return {}
  },
  methods: {
    logout() {
      console.log("LOGUTUTU");
      localStorage.clear();
      this.$router.push({name: 'Login'});
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>